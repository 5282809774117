<template>
  <div class="logout">
    <DgSpinner />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { storage } from "@/services";
import { identity } from "@/utils";

export default {
  computed: {
    ...mapGetters(["serverConfig"]),
  },
  mounted() {
    const identityServiceAuthentication = storage.get("identity-login");

    if (identityServiceAuthentication) {
      identity.logout();
    } else {
      window.location.replace(`${this.serverConfig.api1}api/v2/logout`);
    }
  },
};
</script>

<style lang="scss" scoped>
.logout {
  min-width: 1140px;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
